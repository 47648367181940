const styles = ({ typography }) => ({
  container: {
    padding: `${typography.pxToRem(64)} 0`,
    "&:first-of-type": {
      paddingTop: 0
    },
  },
});

export default styles;

import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});
type SkillBarProps = {
  level: number;
  title: string;
  color: string;
};

const BorderLinearProgress = withStyles<
  'root' | 'colorPrimary' | 'bar',
  {},
  { barColor: string }
>(theme => ({
  root: {
    height: 10,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: props => props.barColor || '#1a90ff',
  },
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <BorderLinearProgress
          barColor={colorGradient(props.value / 100, min, med, max)}
          variant='determinate'
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function SkillBar({ level, title, color }: SkillBarProps) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === level) {
          clearInterval(timer);
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, level);
      });
    }, 150);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className={classes.root}>
      <Typography>{title}</Typography>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
}

type Color = {
  red: number;
  blue: number;
  green: number;
};

const min = {
  red: 244,
  green: 136,
  blue: 154,
};
const med = {
  red: 255,
  green: 224,
  blue: 104,
};
const max = {
  red: 121,
  green: 212,
  blue: 94,
};

/**
 * You may use this function with both 2 or 3 interval colors for your gradient.
 * For example, you want to have a gradient between Bootstrap's danger-warning-success colors.
 */
function colorGradient(
  fadeFraction: number,
  rgbColor1: Color,
  rgbColor2: Color,
  rgbColor3?: Color
) {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: Math.floor(color1.red + diffRed * fade),
    green: Math.floor(color1.green + diffGreen * fade),
    blue: Math.floor(color1.blue + diffBlue * fade),
  };

  return (
    'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')'
  );
}

import React from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import styles from './style';
import { Link } from '@material-ui/core';

interface Props {
  classes: any;
  data: {
    childMarkdownRemark: {
      excerpt: any;
      excerptAst: any;
      htmlAst: any;
      html: any;
      frontmatter: {
        title: string;
        url: string;
        image: any;
      };
    };
  };
}

function BlogPost({ classes, data }: Props) {
  const markdown = data.childMarkdownRemark;
  const { frontmatter } = markdown;
  const img = getImage(frontmatter.image);
  return (
    <div className={classes.container}>
      {frontmatter.image ? (
        <GatsbyImage image={img} alt={frontmatter.title} />
      ) : null}
      <Typography variant='h2' color='textPrimary'>
        {frontmatter.title}
      </Typography>
      {/* <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: markdown.excerpt,
        }}
      /> */}
      <Typography variant='body1'>{markdown.excerpt}</Typography>
      {frontmatter.url ? (
        <Link
          href={frontmatter.url}
          target='_blank'
          variant='body2'
          color='textSecondary'>
          Zur Website
        </Link>
      ) : null}
    </div>
  );
}

export default withStyles(styles)(BlogPost);
